/* font-family: "GloberThin"; */
@font-face {
    font-family: "GloberThin";
    src: url("../fonts/GloberThin.eot");
    src: url("../fonts/GloberThin.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GloberThin.woff") format("woff"),
    url("../fonts/GloberThin.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "GloberBold"; */
@font-face {
    font-family: "GloberBold";
    src: url("../fonts/GloberBold.eot");
    src: url("../fonts/GloberBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GloberBold.woff") format("woff"),
    url("../fonts/GloberBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}